var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"px-4 content-spacing"},[_c('title-plus',{staticClass:"mb-10",attrs:{"title":_vm.$t('components.orgUserDetailsManagement.diveIntoTab.topup.headline'),"hidePlus":true}})],1),[_c('FSTable',{attrs:{"fst-id":_vm.fstId,"qso":_vm.qso,"endpoint":_vm.indexDataEndpoint,"headers":_vm.tableHeaders},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_vm._l((data),function(item,itemIndex){return [_c('FSTableRow',{key:itemIndex,attrs:{"textFallbackAlways":""}},[_c('FSTableRowItem',[_c('router-link',{staticClass:"font-bold text-sm text-blue-600",attrs:{"to":{
                  name: 'ViewTrip',
                  params: { id: item.id },
                }}},[_vm._v("#"+_vm._s(_vm._f("onlyLastFive")(item.id)))])],1),_c('FSTableRowItem',[_c('router-link',{staticClass:"font-bold text-sm text-blue-600",attrs:{"to":{
                  name: 'ViewRiderUserProfile',
                  params: { id: item.topup_to_user.id },
                }}},[_vm._v(_vm._s(item.topup_to_user ? item.topup_to_user.full_name : '--'))])],1),_c('FSTableRowItem',[_c('span',{staticClass:"text-sm text-gray-700 font-normal"},[_vm._v(_vm._s(_vm._f("friendlyDateTime")(item.created_at)))])]),_c('FSTableRowItem',[_c('span',{staticClass:"font-bold text-md"},[_vm._v(_vm._s(_vm.getCurrencySymbol)+" "+_vm._s(item.amount))])]),_c('FSTableRowItem',[(item.status == 'C')?_c('span',{staticClass:"success bg-green-500 py-1 text-white px-4 rounded"},[_vm._v("Success")]):_vm._e(),(item.status == 'P')?_c('span',{staticClass:"error bg-red-500 py-1 text-white px-4 rounded"},[_vm._v("Pending")]):_vm._e()]),_c('FSTableRowItem',[_c('div',{staticClass:"flex items-center"},[_c('router-link',{staticClass:"text-gray-500 text-lg mx-2",attrs:{"to":"#"}},[_c('i',{staticClass:"fas fa-eye"})]),_c('router-link',{staticClass:"text-gray-500 text-lg mx-2",attrs:{"to":"#"}},[_c('i',{staticClass:"fas fa-download    "})])],1)])],1)]})]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }